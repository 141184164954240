import React from "react"

export default function() {
  return (
    <section style={{margin: 0}}>
        <div
          className="col-md-12"
          style={{background: "#58b0da29",color: "#443636",paddingTop: "3em",paddingBottom: "2em"}}
        >
          <div style={{width: "100%"}}>
              <p>
                Sheer Analytics and Insights is a market research firm that offers a range of market research reports, including syndicated, passport, and custom reports. Here is a brief overview of each type:
              </p>
              <br />
              <h1>Syndicated Reports: </h1>
              <p>Sheer Analytics and Insights provides a wide range of syndicated reports covering various industries, including healthcare, technology, energy, and consumer goods. These reports are pre-packaged and provide insights into the market size, trends, competition, and growth opportunities. They are available for purchase by multiple clients and are a cost-effective way for businesses to gain insights into a particular industry or market.</p>
              <h1>Passport Reports: </h1>
              <p>Sheer Analytics and Insights also offers Passport reports, which are syndicated reports prepared by Euromonitor International. Passport reports provide comprehensive market intelligence for various industries and countries, including market size, market segmentation, and consumer behavior. These reports are highly detailed and provide valuable insights into the market dynamics of specific industries and regions.</p>
              <h1>Custom Reports: </h1>
              <p>Sheer Analytics and Insights offers custom research services tailored to meet the specific needs of clients. These reports are customized to address the client's research objectives and provide insights into specific markets or industries. Custom reports are highly detailed and can include in-depth analysis of market trends, consumer behavior, competitive landscape, and other critical factors.</p>
          </div>
          <p>Overall, Sheer Analytics and Insights offers a range of market research report services, including syndicated, passport, and custom reports. These reports provide valuable insights into various industries and markets and help businesses make informed decisions about their business strategies.</p>
          <p>For any queries reach us at <a href="mailto:reportservices@sheeranalyticsandinsights.com">reportservices@sheeranalyticsandinsights.com</a></p>
        </div>
    </section>
  )
}
